import React from "react";

export const Info = ({children, title, titleVariante}) => {

  const TitleInfo = () => {
    switch (titleVariante) {
      case 'h3':
        return <h3>{title}</h3>
      case 'h4':
        return <h4>{title}</h4>
      case 'h1':
        return <h1>{title}</h1>
      default:
        return <h2>{title}</h2>
    }
  }
  return (
    <div className="info">
      <TitleInfo />
      {children}
    </div>
  )
}
