import React from "react";
import { useRef } from "react";
import { EmailInput } from "./EmailInput";
import { failureNotification, emailValidationRegex } from "../formUtils";

const reportEventToGTM = (emailAddress) => {
  console.log(emailAddress);
  window.dataLayer.push({
    event: "event",
    eventProps: {
      category: "Home page action",
      action: "Email submission",
      label: "Email submission",
      value: emailAddress,
    },
  });
};

export const EmailForm = ({ buttonTitle, buttonClass, cta, successAction }) => {
  const formRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const submittedEmail = formData.get("email");
    reportEventToGTM(submittedEmail);
    if (emailValidationRegex.test(submittedEmail)) {
      fetch(
        "https://325wv0zf37.execute-api.us-west-2.amazonaws.com/Prod/submitForm",
        {
          method: "POST",
          body: JSON.stringify({
            email: submittedEmail,
            CTA: cta,
          }),
        }
      );
      e.target.reset();
      successAction();
    } else {
      failureNotification("Invalid email address");
    }
  };
  return (
    <div>
      <form className="email_form" ref={formRef} onSubmit={handleSubmit}>
        <EmailInput />
        <button className={buttonClass} type="submit">
          {buttonTitle}
        </button>
      </form>
    </div>
  );
};
