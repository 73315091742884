import React from "react";
import { Carousel } from "antd";
import { Slide } from "./Slide";

export const Slider = ({ slides, setRef }) => {
  const dotsClass = {
    className: "slider_dots",
  };

  return (
    <Carousel
      ref={(node) => setRef(node)}
      dots={dotsClass}
      dotPosition="bottom"
    >
      {slides.map((slide, index) => (
        <Slide slideData={slide} key={index}/>
      ))}
    </Carousel>
  );
};
