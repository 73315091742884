import React, { useState } from "react";
import { Slider } from "../Slider";

export const OurView = ({ refProp }) => {
  const slides = [
    {
      title: "HOW WE SEE DATA SCIENCE",
      mainTitle: "#1 The Choice",
      text:
        "Businesses are demanding more than accurate predictions. Decision AI helps you deliver real results.",
      img: {
        imgUrl: "imgs/cartoon1.png",
        alt: "Comparison of data scientists",
      },
    },
    {
      title: "HOW WE SEE DATA SCIENCE",
      mainTitle: "#2 The View From Both Sides",
      text:
        "Think this is just a communication problem? We think it’s something deeper. Data scientists need to bring business context into their analysis in ways that were impossible with conventional ML alone.",
      img: {
        imgUrl: "imgs/cartoon2.png",
        alt: "Poor communication due to incomplete analysis",
      },
    },

    {
      title: "HOW WE SEE DATA SCIENCE",
      mainTitle: "#3 Doing The Whole Job",
      text:
        "Don't hand off your model without ensuring it is used effectively. Optimizing how you use predictions has a much bigger payoff than conventional model tuning.",
      img: {
        imgUrl: "imgs/cartoon3.png",
        alt: "The problem with business rules",
      },
    },
    {
      title: "HOW WE SEE DATA SCIENCE",
      mainTitle: "#4 Distribution Matters",
      text:
        "So much analysis uses just averages or point estimates. But small deviations early in a complex system can have big impacts to the bottom line. Decision AI helps you track full distributions to deliver more accurate bottom-line results.",
      img: {
        imgUrl: "imgs/cartoon4.png",
        alt: "Why Decision AI tracks statistical distributions",
      },
    },
  ];
  const [ref, setRef] = useState();
  return (
    <section className="ourview" id="our_view" ref={refProp}>
      <div className="wrapper">
        <div className="slider">
          <button
            onClick={() => ref.prev()}
            className="slider__controll slider__controll--left"
          />
          <button
            onClick={() => ref.next()}
            className="slider__controll slider__controll--right"
          />
          <Slider setRef={setRef} slides={slides} />
        </div>
      </div>
    </section>
  );
};
