import React from "react";
import { Info } from "../Info";
import { Logo } from "../Logo";
import { EmailForm } from "../EmailForm";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { successNotification } from "../../formUtils";

const footerLinks = [
  {link: '', name: 'What Is It?'},
  {link: '#demo', name: 'Demo'},
  {link: '#key_ideas', name: 'Key Ideas'},
  {link: '#our_view', name: 'Our View'},
  {link: 'https://app.decision.ai/', name: 'Login'},
]

export const Footer = () => {
  const breakpoints = useBreakpoint();
  return (
    <footer className="footer">
      <nav className="footer__nav">
        {breakpoints.lg && <Logo />}
        <ul>
          {
            footerLinks.map((item, index) => (
              <li key={index}>
                {
                  index === 4 ? 
                    <a href={item.link} target="_blank" rel="noopener noreferrer">{item.name}</a> : <a href={item.link}>{item.name}</a>
                }                
              </li>
            ))
          }          
        </ul>
      </nav>
      <div className="footer__card">
        <Info title="Join the Decision AI Mailing List" titleVariante="h4">
          <p>
            Interested in results-driven AI? Learn how you can go beyond
            predictions to optimize business decisions.
          </p>
          <EmailForm
            buttonTitle="Sign Up"
            buttonClass="button"
            cta="Email List Signup"
            successAction={() =>
              successNotification("Congrats. You are signed up")
            }
          />
        </Info>
      </div>
    </footer>
  );
};
