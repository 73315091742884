import React, { useRef } from "react";
import { useState } from "react";
import {
  successNotification,
  emailValidationRegex,
  failureNotification,
} from "../formUtils";

export const Modal = ({ isOpen, setIsOpen }) => {
  const formRef = useRef(null);
  const [isSent, setIsSent] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const submittedEmail = formData.get("email");
    if (emailValidationRegex.test(submittedEmail)) {
      fetch(
        "https://325wv0zf37.execute-api.us-west-2.amazonaws.com/Prod/submitForm",
        {
          method: "POST",
          body: JSON.stringify({
            name: formData.get("name"),
            email: submittedEmail,
            company: formData.get("company"),
            message: formData.get("message"),
            CTA: "Demo Request",
          }),
        }
      ).then((res) => {
        setIsSent(true);
        setIsOpen(false);
        successNotification("We will follow up in the next 24 hours.");
      });
    } else {
      failureNotification("Invalid email address");
    }
  };

  return (
    <div
      className="modal_wrapper"
      style={{ display: `${isOpen ? "block" : "none"}` }}
      onClick={(e) => {
        if (e.target.classList.contains("modal_wrapper")) {
          setIsOpen(false)
        };
      }
      }
    >
      <div className="modal card">
        <button className="close_button" onClick={() => setIsOpen(false)}>
          <img src="imgs/CloseIcon.svg" alt="" />
        </button>
        <h4>Get a free demo</h4>
        <h5>Talk to an expert about the potential of Decision AI using your specific use case.</h5>
        {isSent ? (
          <>
            <div className="success_message">
              <p>Message sent!</p>
              <h5>We’ll follow up within 24 hours.</h5>
              <button type="button" className="button" onClick={() => setIsOpen(false)}>Close</button>
            </div>
          </>
        ) : (
            <>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="form_fields">
                  <div>
                    <input placeholder="Name" name="name" type="text" />
                    <input placeholder="Email" name="email" type="text" />
                    <input placeholder="Company" name="company" type="text" />
                  </div>
                  <textarea
                    placeholder="Message"
                    name="message"
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <button type="submit" className="button button_primary">
                  Send
              </button>
              </form>
            </>
          )}
      </div>
    </div>
  );
};
