import React from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { AccountedList } from "../AccountedList";
import { Info } from "../Info";
import LaptopImage from "../../assets/laptop.png";
import Video from "../../assets/video.mp4";

export const Home = () => {
  const breakpoints = useBreakpoint();
  return (
    <>
      <section className="home" id="top">
        <div className="wrapper">
          <Info title="Go beyond predictions." titleVariante="h3">
            <h3 className="special-title">Rigorously optimize bottom line results in complex systems.</h3>
            <p>Think your boss cares about predictions? They don't. They care about results.</p>
          </Info>
          <div className="main-image">
            <img src={LaptopImage} alt="laptop"/>
            <video src={Video} autoPlay="autoplay" loop muted></video>
          </div>
        </div>
        <div className="problem_solution">
          <div className="title">
            <h5>A MORE COMPLETE BUSINESS SIMULATION</h5>
            <h3>Integrate Mental Models and ML Models</h3>
          </div>
          {
            ((breakpoints.sm && !breakpoints.lg ) || breakpoints.xs) && <AccountedList />
          }
          <div className="graph">
            <Info>
              <p>
                Use domain knowledge to describe where your machine learning models
                fit into the larger picture.
              </p>
              {breakpoints.lg && <AccountedList />}
            </Info>
            <img
              className="left_img"
              src="imgs/machine-learning.png"
              alt="Machine Learning"
            />
          </div>
        </div>    
      </section>      
    </>
  );
};
