import { notification } from "antd";

export const successNotification = (message) => {
  notification["success"]({
    message: "Success",
    description: message,
    duration: 4,
  });
};

export const failureNotification = (message) => {
  notification["error"]({
    message: "Change Required",
    description: message,
    duration: 3.5,
  });
};

export const emailValidationRegex = /\S+@\S+\.\S+/;
