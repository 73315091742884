import React, { useState } from "react";

import "antd/dist/antd.css";
import "./App.css";
import { Header } from "./components/sections/Header";
import { Home } from "./components/sections/Home";
import { WhyDecision } from "./components/sections/WhyDecision";
import { OurView } from "./components/sections/OurView";
import { Footer } from "./components/sections/Footer";
import { Modal } from "./components/Modal";
import TagManager from "react-gtm-module";

import ReactGA from "react-ga";

const tagManagerArgs = {
  gtmId: "GTM-TTVVXBZ",
};

TagManager.initialize(tagManagerArgs);

const googleAnalyticsTrackingId = "UA-155304517-1";
ReactGA.initialize(googleAnalyticsTrackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Header setIsModalOpen={setIsModalOpen} />
      <Home />
      <WhyDecision />
      <OurView />
      <div className="wrapper">
        <Footer />
      </div>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
}

export default App;
