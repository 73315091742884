import React from "react";

export const Slide = ({ slideData }) => {
  return (
    <>
      <div className="title">
        <h5>{slideData.title}</h5>
        <h3>{slideData.mainTitle}</h3>
      </div>
      <p>{slideData.text}</p>
      <img src={slideData.img.imgUrl} alt={slideData.img.alt} />
    </>
  );
};
