import React, { useState } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Logo } from "../Logo";
import { Navigation } from "../Navigation";

export const Header = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const breakpoints = useBreakpoint();
  const [isOpenNav, setIsOpenNav] = useState(false);

  return (
    <>
      <header className="header" style={scrollPos > 40 || isOpenNav ? { backgroundColor: 'white', boxShadow: '0 0 36px rgba(0,0,0,.2)' } : {}}>
        <div className="wrapper">
          <nav className="nav">
            <Logo />
            {breakpoints.lg ? (
              <div className="link-group">
                <Navigation setScrollPos={setScrollPos} />
                <a href="https://app.decision.ai/" target="_blank" rel="noopener noreferrer" className="login-link">Login</a>
                <a href="https://app.decision.ai/register" className="button button_primary signup-link" target="_blank" rel="noopener noreferrer">
                  Sign up for free
                </a>                
              </div>
            ) :
              (
                <button onClick={() => setIsOpenNav(!isOpenNav)} type="button">
                  <img src="imgs/MenuIcon.svg" alt="" />
                </button>
              )}
          </nav>
          {breakpoints && (
            <Navigation isOpen={isOpenNav} setScrollPos={setScrollPos} />                   
          )}
        </div>
      </header>
      {isOpenNav && <div onClick={() => setIsOpenNav(false)} className="header_bg"></div>}
    </>
  );
};
