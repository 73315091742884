import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "./assets/modal-video.min.css";
import PlayImg from "../../assets/play.svg";
import VideoImg from "../../assets/video.png";

const decisions = [
  {
    title: "Collaborate Better",
    description:
      "Share an interactive dashboard that lets colleagues and stakeholders explore your results. Send them a link, and it will always be up to date with your simulation.",
  },
  {
    title: "Embrace Popular ML Frameworks",
    description:
      "Build models with tools you know like Tensorflow, scikit-learn and xgboost. Import your model in seconds and embed it into a more holistic simulation.",
  },
  {
    title: "Incorporate Uncertainty",
    description: `Models aren't perfect. See the potential upside and downside risk from each decision.`,
  },
  {
    title: "Focus on Deeper Oucomes",
    description: `Most people don't care about predictions. They want results. See the bottom line result of each decision you make.`,
  },
];

export const WhyDecision = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="why-decision">
      <div className="wrapper">
        <div className="demo-video" id="demo">
          <div className="video-wrapper">
            <img src={VideoImg} alt="" className="video-image" />
            <p>See it in action</p>
            <div className="play-btn-wrapper">
              <img src={PlayImg} alt="" onClick={() => setOpen(true)} />
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="-WqWy5a9c8o"
            onClose={() => setOpen(false)}
          />
        </div>
        <div className="decision-list" id="key_ideas">
          {decisions.map((item, index) => (
            <div className="decision-item" key={index}>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>      
    </section>
  );
};
