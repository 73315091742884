import React, { useState,  useEffect } from "react";
import { Tabs } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export const Navigation = ({ isOpen = true, setScrollPos }) => {
  const [activeKey, setActiveKey] = useState("top");
  const { TabPane } = Tabs;
  const breakpoints = useBreakpoint();
  const sections = ['top', 'demo', 'key_ideas', 'our_view']

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    setScrollPos(window.pageYOffset);

    // eslint-disable-next-line array-callback-return
    sections.map(item => {
      const element = document.getElementById(item);

      if(element) {
        const top = parseInt(element.offsetTop);
        const height = parseInt(element.offsetHeight);

        if(window.scrollY - top >= -1 && window.scrollY - top <= height) {
          setActiveKey(item);
        }
      }
    })
  }

  return (
    <>
      <Tabs
        tabPosition={breakpoints.xs ? "right" : "top"}
        style={{ display: `${isOpen ? "flex" : "none"}` }}
        activeKey={activeKey}
        defaultActiveKey={"top"}
        onChange={(e) => {
          window.location.href = "#" + e;
        }}
      >
        <TabPane tab="What Is It?" key="top" />
        <TabPane tab="Demo" key="demo" />
        <TabPane tab="Key Ideas" key="key_ideas" />
        <TabPane tab="Our View" key="our_view" />        
      </Tabs>
      {
        breakpoints.xs && isOpen ?
        <div className="addition-part">
          <div className="login-part">
            <a href="https://app.decision.ai/" target="_blank" rel="noopener noreferrer" className="login-link">Login</a>
          </div>
          <div className="signup-part">
            <a href="https://app.decision.ai/register" className="button button_primary signup-link" target="_blank" rel="noopener noreferrer">
              Sign up for free
            </a>  
          </div>        
        </div> : <></>
      }
    </>    
  );
};
