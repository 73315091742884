import React from "react";

export const AccountedList = () => {
  return (
    <ul className="accounted_list">
      <li>ML Model</li>
      <li>Mental Model</li>
    </ul>
  )
}
